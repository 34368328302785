import React from 'react'

import { useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const Locale = ({ type, locale }) => {
  const { getTranslation } = useLanguageContext()

  return (
    <div className={`${styles.locale} ${styles[type]}`} id={`${type}_language`}>
      {getTranslation(locale)}
    </div>
  )
}

export default Locale
