import React from 'react'

import { useLanguageContext } from '@cvg/react-hooks'

import { RECORD_STATUS } from '@utils/constants'

import styles from './styles.module.css'

const Status = ({ status, type }) => {
  const { getTranslation } = useLanguageContext()

  return (
    <p className={styles.status} data-status-type={type}>
      {getTranslation(RECORD_STATUS[status])}
    </p>
  )
}

export default Status
