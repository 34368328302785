import React from 'react'

import Locale from '../Locale'
import { TRANSLATION_TYPES } from '@utils/constants'

import styles from './styles.module.css'

const Tabs = ({ languages, setView, view }) => {
  return (
    <div className={styles.tabs} data-view={view}>
      <div className={styles.buttons}>
        <button onClick={() => setView(TRANSLATION_TYPES.source)}>
          <Locale locale={languages.source} type={TRANSLATION_TYPES.source} />
        </button>
        <button onClick={() => setView(TRANSLATION_TYPES.target)}>
          <Locale locale={languages.target} type={TRANSLATION_TYPES.target} />
        </button>
      </div>
      <div className={styles.slider} />
      <div className={styles.bg} />
    </div>
  )
}

export default Tabs
