import { Input } from '@cvg/react-components'
import React, { useState } from 'react'

// Based on https://dev.to/andrewchmr/react-hh-mm-ss-time-input-cfl
const TimeInput = ({ onChange, value, ...rest }) => {
  const handleOnChange = event => {
    setTimeValue(event.target.value)
  }

  const onBlur = ({ target }) => {
    const { name, value } = target
    const milliseconds = Math.max(0, getMillisecondsFromHHMMSS(value))

    const time = toHHMMSS(milliseconds)
    setTimeValue(time)

    const event = {
      target: {
        name,
        value: milliseconds
      }
    }
    onChange && onChange(event)
  }

  const getMillisecondsFromHHMMSS = value => {
    const [str1, str2, str3] = value.split(':')

    const val1 = Number(str1)
    const val2 = Number(str2)
    const val3 = Number(str3)

    if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
      return val1 * 1000
    }

    if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
      return (val1 * 60 + val2) * 1000
    }

    if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
      return (val1 * 60 * 60 + val2 * 60 + val3) * 1000
    }

    return 0
  }

  const toHHMMSS = ms => {
    const secNum = parseInt(ms.toString(), 10) / 1000
    const hours = Math.floor(secNum / 3600)
    const minutes = Math.floor(secNum / 60) % 60
    const seconds = secNum % 60

    return [hours, minutes, seconds]
      .map(val => (val < 10 ? `0${val}` : val))
      .filter((val, index) => val !== '00' || index > 0)
      .join(':')
      .replace(/^0/, '')
  }

  const [timeValue, setTimeValue] = useState(toHHMMSS(value))

  return <Input onChange={handleOnChange} onBlur={onBlur} value={timeValue} {...rest} />
}

export default TimeInput
