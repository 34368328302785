import { useEffect, useMemo } from 'react'

import { getContextReviews, getContextReviewsCacheKey } from '@services/contentReview'

import { useFetch, useIsInView, useLanguageContext } from '@cvg/react-hooks'
import { useSearchContext } from '../AppProviders/Search'

const useContextCard = context => {
  const { languageId } = useLanguageContext()
  const { search } = useSearchContext()

  const {
    data = [],
    isLoading,
    isValidating,
    setSize
  } = useFetch(getContextReviews, {
    pagination: {
      getCacheKey: (index, previousData) => getContextReviewsCacheKey(context, index, previousData, languageId, search)
    },
    swrOptions: {
      // necessary for detecting records already being reviewed by other moderators
      revalidateAll: true
    }
  })

  const { totalReviews = 0 } = data[0] || {}
  const reviews = useMemo(() => data.flatMap(page => page.reviews), [data])
  const hasMoreReviews = reviews.length < totalReviews

  const { isInView, observerRef, rootRef } = useIsInView({ rootMargin: '0px 0px 200px 0px' })

  useEffect(() => {
    if (isInView && hasMoreReviews) setSize(previousSize => previousSize + 1)
  }, [isInView, hasMoreReviews])

  return { hasMoreReviews, isLoading, isValidating, observerRef, reviews, rootRef, totalReviews }
}

export default useContextCard
