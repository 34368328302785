import React from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import AddIcon from '@components/Icons/Add'
import styles from './styles.module.css'

const AddSubtitle = ({ transcription }) => {
  const { getTranslation } = useLanguageContext()
  const { currentStep, updateData } = useCustomContext()

  const addSubtitle = () => {
    const { id: lastId = '0', endTime: lastEndTime = 0 } = transcription?.data[transcription.data.length - 1] || {}

    const event = {
      target: {
        name: transcription.id,
        value: transcription.data.concat([
          {
            id: (parseInt(lastId) + 1).toString(),
            startTime: lastEndTime,
            endTime: lastEndTime + 1000,
            text: ''
          }
        ])
      }
    }

    updateData(event, currentStep)
  }

  if (transcription.readOnly) return null

  return (
    <button aria-label={getTranslation('Add Subtitle')} className={styles.add} onClick={addSubtitle} type='button'>
      <span />
      <span />
      <div />
      <AddIcon />
    </button>
  )
}

export default AddSubtitle
