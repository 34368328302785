import React from 'react'

import { MEDIA_TYPES } from '@utils/constants'
import { getTranslationLanguages } from '@utils/localization'

import Error from '@components/Error'
import Photo from './Photo'
import Select from './Select'
import Textarea from './Textarea'
import Translation from './Translation'
import Video from './Video'
import VideoTranscription from './VideoTranscription'

const Media = ({ content }) => {
  if (content.mediaType === MEDIA_TYPES.text) {
    const languages = getTranslationLanguages(content)

    if (languages.source !== languages.target) {
      return <Translation content={content} languages={languages} />
    } else if (content.contentItems.every(({ allowedValues }) => allowedValues?.length > 0)) {
      return <Select content={content} />
    } else return <Textarea content={content} />
  }

  if (content.mediaType === MEDIA_TYPES.image) {
    return <Photo content={content} />
  }

  if (content.mediaType === MEDIA_TYPES.video) {
    return <Video content={content} />
  }

  if (content.mediaType === MEDIA_TYPES.transcription) {
    return <VideoTranscription content={content} />
  }

  return <Error />
}

export default Media
