import React from 'react'
import { Link } from 'react-router-dom'

import { useLanguageContext } from '@cvg/react-hooks'

import styles from '../styles.module.css'

const SignOut = () => {
  const { getTranslation } = useLanguageContext()

  return (
    <div className={styles.setting}>
      <Link to='/logout'>{getTranslation('Sign Out')}</Link>
    </div>
  )
}

export default SignOut
