import React from 'react'

import { useCustomContext } from '@cvg/react-hooks'
import { useLanguageContext } from '@cvg/react-hooks'

import { STEP_ACTIONS } from '@utils/constants'

import FlaggedContentItems from '../FlaggedContentItems'

import styles from './styles.module.css'

const Flagged = () => {
  const { getTranslation } = useLanguageContext()
  const { goToPreviousStep, steps } = useCustomContext()

  return (
    <>
      {steps
        .filter(step => step.action === STEP_ACTIONS.review && !step.approved)
        .map(step => ({
          number: step.number,
          captionLong: step.captionLong,
          flaggedItems: step.contentCards
            .flat()
            .filter(cards => cards.contentItems.some(item => item.flags.length))
            .flatMap(item => item.contentItems)
        }))
        .map(step => (
          <div className={styles.step} key={step.number}>
            <button onClick={() => goToPreviousStep(step.number)}>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                <path d='M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z' />
              </svg>
              <b>{getTranslation('Step ___:', step.number)}</b> {getTranslation(getTranslation(step.captionLong))}
            </button>

            <FlaggedContentItems contentItems={step.flaggedItems} key={`flagged${step.number}`} />
          </div>
        ))}
    </>
  )
}

export default Flagged
