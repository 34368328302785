import React from 'react'

import styles from './styles.module.css'

const TextMetadata = ({ metadata }) => {
  if (metadata.length === 0) return null
  return (
    <div className={styles['text-metadata']}>
      <ul>
        {metadata.map((item, idx) => (
          <li key={`text-metadata-${idx}`}>
            <h3>{item.label}:</h3>
            <p>{item.value}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default TextMetadata
