import React from 'react'
import { createRoot } from 'react-dom/client'

import App from '@components/App'

import './styles/reset.css'
import './styles/index.css'

const root = createRoot(document.getElementById('app'))

root.render(<App />)
