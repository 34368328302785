export const E2E_VALUES = Object.freeze({
  context: 'e2e',
  image: 'https://www.image.com',
  email: 'cvg-test@us.ci.org',
  pin: '485163',
  sourceLanguage: 'en',
  flags: ['flag0', 'flag1', 'flag2'],
  text: 'text data',
  video: 'https://www.video.com'
})
