import React from 'react'

import { DropDownMenu } from '@cvg/react-components'
import { useLanguageContext } from '@cvg/react-hooks'

import auth from '@services/auth'

import Language from './Language'
import Notifications from './Notifications'
import UserAdmin from './UserAdmin'
import SignOut from './SignOut'

import styles from './styles.module.css'

const Settings = () => {
  const { getTranslation } = useLanguageContext()

  if (!auth.getUser()) return null

  return (
    <DropDownMenu align='right' className={styles.settings}>
      <DropDownMenu.Button aria-label={getTranslation('Settings')} />
      <DropDownMenu.Items>
        <Language />
        <Notifications />
        <UserAdmin />
        <SignOut />
      </DropDownMenu.Items>
    </DropDownMenu>
  )
}

export default Settings
