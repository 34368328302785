import React from 'react'
import PropTypes from 'prop-types'

import Media from './Media'
import Flags from './Flags'

import styles from './styles.module.css'

const FlaggedContentItems = ({ contentItems }) => {
  return (
    <div className={styles['flagged-items']}>
      {contentItems
        .filter(item => item.flags.length)
        .map((item, idx) => (
          <div className={styles['flagged-item']} key={`flagged_${idx}`}>
            <Media data={item.data} mediaType={item.mediaType} />
            <Flags flags={item.flags} />
          </div>
        ))}
    </div>
  )
}

FlaggedContentItems.propTypes = {
  contentItems: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default FlaggedContentItems
