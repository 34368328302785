import React from 'react'

import { Input } from '@cvg/react-components'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import { USER_MODES } from '@utils/constants'

const Languages = () => {
  const { callbacks, currentUser, state } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const { setUser } = callbacks

  return (
    <fieldset>
      <legend>{getTranslation('Languages')}</legend>
      <Input
        displayOptions={{
          showAllOptions: state.mode === USER_MODES.addUser,
          showUnselectedLabel: getTranslation('Add Language'),
          hideUnselectedLabel: getTranslation('Hide Unselected Languages')
        }}
        name='languages'
        options={currentUser.permittedLanguages}
        onSelect={selectedLanguages => setUser('languages', selectedLanguages)}
        selected={state.user.languages}
        type='multiselect'
      />
    </fieldset>
  )
}

export default Languages
