import { isNotEmpty, isNumeric } from '@cvg/validators'

const isValidTranscription = ({ value }) => {
  if (!Array.isArray(value)) return false
  if (value.length === 0) return true
  if (value.length === 1) return value[0].startTime < value[0].endTime

  for (let i = 1; i < value.length; i++) {
    const prev = value[i - 1]
    const curr = value[i]
    if (prev.endTime > curr.startTime) return false
    if (curr.startTime > curr.endTime) return false
  }

  return true
}

export const VALIDATIONS = {
  currency: { validator: isNumeric, message: 'Must be in currency format' },
  required: { validator: isNotEmpty, message: 'Required field' },
  transcription: { validator: isValidTranscription, message: 'TRANSCRIPTION_ERROR' }
}

export const validateStep = step => {
  const errors = {}

  step.contentCards.flat().forEach(card => {
    card.contentItems.forEach(item => {
      item.validations.forEach(({ validator, message }) => {
        if (!validator({ contentItems: card.contentItems, value: item.data })) errors[item.id] = message
      })
    })
  })

  return errors
}
