import { REVIEWS_QUERY_LIMIT, STEP_ACTIONS } from '@utils/constants'

import { get, put } from '@services/clients/contentReview'

// used with useSWRInfinite -- paths are generated dynamically under the hood
export const getContextReviews = async pathFromCacheKey => {
  return await get(pathFromCacheKey)
}

// used with useSWRInfinite to dynamically generate cache keys
export const getContextReviewsCacheKey = (context, index, previousData, languageId, searchText) => {
  if (previousData && !previousData.reviews.length) return null

  const searchParam = searchText ? `&search=${searchText}` : ''

  // this is passed to getContextReviews under the hood
  return `/reviews/contexts/${context}?limit=${REVIEWS_QUERY_LIMIT}&page=${index}&language=${languageId}${searchParam}`
}

export const getReview = async (id, language) => {
  return await get(`/reviews/${id}?language=${language}`)
}

export const postReview = async ({ id, language, state }) => {
  const payload = {
    comment: state.comment,
    contentItems: state.steps
      .filter(step => step.action === STEP_ACTIONS.review)
      .flatMap(({ contentCards }) => contentCards.flat())
      .flatMap(card =>
        card.contentItems.flatMap(item => ({
          approved: item.approved,
          id: item.id,
          language,
          mediaType: item.mediaType,
          rejectionReasons: item.flags,
          ...(item.translation
            ? { updatedData: item.translation.source?.data, updatedTranslation: item.translation.target?.data }
            : { updatedData: item.data })
        }))
      )
  }

  return await put(`/reviews/${id}/`, payload)
}
