import React from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import { USER_MODES } from '@utils/constants'
import { filterUsers, sortByNameAndEmail } from '@utils/user'

import UserCard from '@components/UserCard'
import Filters from './Filters'

import styles from './styles.module.css'

const Users = () => {
  const { getTranslation } = useLanguageContext()
  const { callbacks, state, users } = useCustomContext()

  return (
    <div className={styles.users}>
      <h2>{getTranslation('User Admin')}</h2>
      <div className={styles.actions}>
        <Filters />
        <button onClick={() => callbacks.openUser(USER_MODES.addUser)}>{getTranslation('Add User')}</button>
      </div>
      <ul className={styles['user-cards']}>
        {filterUsers(users, state.filters)
          .toSorted(sortByNameAndEmail)
          .map((user, idx) => (
            <UserCard key={idx} openUser={callbacks.openUser} user={user} />
          ))}
      </ul>
    </div>
  )
}

export default Users
