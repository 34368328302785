import React from 'react'

import { VideoComponents } from '@cvg/react-components'
const { VideoPlayer, VIDEO_PROVIDER } = VideoComponents

import styles from './styles.module.css'

const Video = ({ data }) => {
  return (
    <div className={styles.video}>
      <VideoPlayer className={styles.video} videoType={VIDEO_PROVIDER.url} videoUrl={data} />
    </div>
  )
}

export default Video
