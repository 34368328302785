import React from 'react'

import { Input } from '@cvg/react-components'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

const Notifications = () => {
  const { callbacks, state } = useCustomContext()
  const { getTranslation, supportedLanguages } = useLanguageContext()

  const { setUser } = callbacks

  return (
    <fieldset>
      <legend>{getTranslation('Email Notifications')}</legend>
      <div data-flex>
        <Input
          label={getTranslation('Receive daily emails for pending reviews')}
          name='notificationsEnabled'
          onClick={e => setUser(e.target.name, !state.user.notificationsEnabled)}
          type='toggle'
          value={state.user.notificationsEnabled}
        />

        <Input
          disabled={!state.user.notificationsEnabled}
          label={getTranslation('Preferred language for emails')}
          name='locale'
          onChange={e => setUser(e.target.name, e.target.value)}
          type='select'
          value={state.user.locale}
        >
          {supportedLanguages.map(language => (
            <option key={language.id} value={language.id}>
              {language.name}
            </option>
          ))}
        </Input>
      </div>
    </fieldset>
  )
}

export default Notifications
