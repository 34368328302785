import React from 'react'

import { useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const Translation = ({ record }) => {
  const { getTranslation } = useLanguageContext()

  return (
    <div className={styles.translation}>
      <div className={styles.locales}>
        <b>{getTranslation(record.sourceLanguage)}</b>

        {record.targetLanguage && record.sourceLanguage !== record.targetLanguage && (
          <>
            <small>&#8594;</small>
            <b>{getTranslation(record.targetLanguage)}</b>
          </>
        )}
      </div>
    </div>
  )
}

export default Translation
