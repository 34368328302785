import React from 'react'

import { useLanguageContext } from '@cvg/react-hooks'

import SelectLanguage from '@components/SelectLanguage'

import styles from '../styles.module.css'

const Language = () => {
  const { getTranslation } = useLanguageContext()

  return (
    <div className={styles.setting}>
      <div>
        <strong>{getTranslation('Language')}</strong>
        <p>{getTranslation('Set your preferred language for viewing the app and email notifications.')}</p>
      </div>
      <SelectLanguage />
    </div>
  )
}

export default Language
