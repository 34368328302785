import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { CustomContextProvider, useFetch } from '@cvg/react-hooks'

import { getUserPermissions } from '@utils/user'
import { CACHE_KEYS } from '@utils/swr'

import auth from '@services/auth'
import { getUser } from '@services/user'

const AuthenticatedRoutes = () => {
  if (!auth.getUser()) return <Navigate to='/login' />

  const {
    data: currentUser = { contexts: [], notificationsEnabled: false },
    error,
    isLoading
  } = useFetch(getUser, { cacheKey: CACHE_KEYS.user })

  getUserPermissions(currentUser)

  return (
    <CustomContextProvider context={{ currentUser, isCurrentUserLoading: isLoading, currentUserError: error }}>
      <Outlet />
    </CustomContextProvider>
  )
}

export default AuthenticatedRoutes
