import React from 'react'

import { TAG_PRIORITIES } from '@utils/constants'

import FCPIcon from '@components/Icons/Church'
import PhotoIcon from '@components/Icons/Photo'
import PhotosIcon from '@components/Icons/Photos'
import SupporterIcon from '@components/Icons/Supporter'
import VideoIcon from '@components/Icons/Video'

import styles from './styles.module.css'

const Tags = ({ tags = [] }) => {
  const sortTags = (a, b) =>
    (TAG_PRIORITIES[a.type] || Object.keys(TAG_PRIORITIES).length + 1) -
    (TAG_PRIORITIES[b.type] || Object.keys(TAG_PRIORITIES).length + 1)

  const Icons = {
    fcp: <FCPIcon />,
    photo: <PhotoIcon />,
    photos: <PhotosIcon />,
    supporter: <SupporterIcon />,
    video: <VideoIcon />
  }

  return (
    <div className={styles.tags}>
      {tags
        .toSorted((a, b) => sortTags(a, b))
        .map(tag => (
          <div className={styles.tag} key={tag.value}>
            {Object.keys(Icons).includes(tag.type) && Icons[tag.type]}
            <span>{tag.value}</span>
          </div>
        ))}
    </div>
  )
}

export default Tags
