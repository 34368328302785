import { useReducer } from 'react'
import { useNavigate } from 'react-router-dom'

import { useFetch, useCustomContext, useLanguageContext, useSubmit } from '@cvg/react-hooks'
import { showError, showSuccess } from '@cvg/react-components'

import { getUpsertUserErrorMessage } from '@utils/user'
import { USER_ROLES } from '@utils/constants'
import { CACHE_KEYS, updateUserCaches, removeUserFromCache } from '@utils/swr'
import { deactivateUser, getUsers, upsertUser } from '@services/user'

const useUserAdminData = () => {
  const { getTranslation, languageId } = useLanguageContext()
  const { isCurrentUserLoading, currentUser, currentUserError } = useCustomContext()
  const navigate = useNavigate()

  if (!currentUser.canManageUsers && !isCurrentUserLoading) navigate('/')

  const initialState = {
    filters: {
      nameOrEmail: '',
      role: ''
    },
    mode: '',
    showDeactivationWarning: false,
    user: {
      contexts: [],
      countries: [],
      email: '',
      languages: [],
      locale: languageId,
      notificationsEnabled: false,
      role: USER_ROLES.reviewer,
      userId: ''
    }
  }

  const [state, dispatch] = useReducer((previousState, action) => {
    const { changes, filter, mode, showDeactivationWarning, user } = action

    switch (action.type) {
      case 'OPEN_USER':
        return { ...previousState, mode, user }

      case 'SET_FILTER':
        return { ...previousState, filters: { ...previousState.filters, ...filter } }

      case 'SET_SHOW_DEACTIVATION_WARNING':
        return { ...previousState, showDeactivationWarning }

      case 'SET_USER':
        return { ...previousState, user: { ...previousState.user, ...changes } }

      case 'RESET':
        return initialState
    }
  }, initialState)

  const {
    data: users = [],
    error: usersError,
    isLoading: isUsersLoading
  } = useFetch(getUsers, { cacheKey: CACHE_KEYS.users })

  const userUpsert = useSubmit(upsertUser, {
    cacheKey: CACHE_KEYS.users,
    onError: error => {
      dispatch({ type: 'RESET' })
      showError(getUpsertUserErrorMessage(error, getTranslation), { isPersistent: true })
    },
    onSuccess: updatedUser => {
      updateUserCaches(updatedUser, currentUser, users)

      dispatch({ type: 'RESET' })
      showSuccess(getTranslation('User update successful.'))
    }
  })

  const userDeactivation = useSubmit(deactivateUser, {
    cacheKey: CACHE_KEYS.users,
    onError: () => {
      dispatch({ type: 'RESET' })
      showError(getTranslation('User deactivation failed.'))
    },
    onSuccess: () => {
      removeUserFromCache(state.user.id, users)

      dispatch({ type: 'RESET' })
      showSuccess(getTranslation('User deactivation successful.'))
    }
  })

  const callbacks = {
    deactivateUser: () => userDeactivation.submit(state.user.userId),
    openUser: (mode, user) => dispatch({ type: 'OPEN_USER', mode, user: user || initialState.user }),
    reset: () => dispatch({ type: 'RESET' }),
    setFilter: filter => dispatch({ type: 'SET_FILTER', filter }),
    setShowDeactivationWarning: showDeactivationWarning =>
      dispatch({ type: 'SET_SHOW_DEACTIVATION_WARNING', showDeactivationWarning }),
    setUser: (key, value) => dispatch({ type: 'SET_USER', changes: { [key]: value } }),
    submitUser: () => userUpsert.submit(state.user)
  }

  return {
    callbacks,
    error: usersError || currentUserError,
    isLoading: isUsersLoading || isCurrentUserLoading,
    isSubmitting: userUpsert.isSubmitting || userDeactivation.isSubmitting,
    state,
    users,
    currentUser
  }
}

export default useUserAdminData
