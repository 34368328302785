import React from 'react'

import { VideoComponents } from '@cvg/react-components'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import { MEDIA_TYPES } from '@utils/constants'

import FlagContent from '@components/ContentCard/FlagContent'

import styles from './styles.module.css'

const { VideoPlayer, VIDEO_PROVIDER } = VideoComponents

const Video = ({ content }) => {
  const { currentStep, flagContent } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const transcriptionIds = currentStep.contentCards
    .flat()
    .filter(content => content.mediaType === MEDIA_TYPES.transcription)
    .map(transcription => transcription.contentItems)
    .map(({ id }) => id)

  const flagTranscriptions = flags => {
    transcriptionIds?.forEach(transcriptionId => flagContent(transcriptionId, flags, currentStep))
  }

  return (
    <div className={styles.videos}>
      <h3>{getTranslation(content.label)}</h3>
      <ul>
        {content.contentItems.map(item => (
          <li key={item.id}>
            <VideoPlayer id='video' videoType={VIDEO_PROVIDER.url} videoUrl={item.data} />
            <FlagContent content={item} onFlagContent={flagTranscriptions} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Video
