import React from 'react'

import { Input } from '@cvg/react-components'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const Text = ({ subtitleId, text, transcription }) => {
  const { currentStep, updateData } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const handleOnChange = ({ target }) => {
    const { name, value } = target

    const event = {
      target: {
        name: transcription.id,
        value: transcription.data.map(data => (data.id === name ? { ...data, text: value } : data))
      }
    }

    updateData(event, currentStep)
  }

  return (
    <Input
      className={styles.text}
      label={getTranslation('Subtitle')}
      name={subtitleId}
      onChange={handleOnChange}
      readOnly={transcription.readOnly}
      type='flexTextarea'
      value={text}
    />
  )
}

export default Text
