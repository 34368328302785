import React from 'react'

import { useLanguageContext } from '@cvg/react-hooks'
import { ModalButtons } from '@cvg/react-components'
const { PhotoModalButton } = ModalButtons

import styles from './styles.module.css'

const Photo = ({ data }) => {
  const { getTranslation } = useLanguageContext()

  return (
    <div className={styles.photo}>
      <PhotoModalButton photo={data}>
        <img alt={getTranslation('Rejected photo')} src={data} />
      </PhotoModalButton>
    </div>
  )
}

export default Photo
