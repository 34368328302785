import React from 'react'

import { useCustomContext } from '@cvg/react-hooks'

import ContentCard from '@components/ContentCard'

import styles from './styles.module.css'

const StepContent = () => {
  const { currentStep } = useCustomContext()

  return (
    <div className={styles['step-content']}>
      {currentStep.contentCards.map((card, idx) => (
        <ContentCard key={idx} content={card} />
      ))}
    </div>
  )
}

export default StepContent
