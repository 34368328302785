import React from 'react'
import PropTypes from 'prop-types'

import { useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const Flags = ({ flags }) => {
  const { getTranslation } = useLanguageContext()

  return (
    <ul className={styles.flags}>
      {flags.map(flag => (
        <li key={flag}>{getTranslation(flag)}</li>
      ))}
    </ul>
  )
}

Flags.propTypes = {
  flags: PropTypes.array.isRequired
}

export default Flags
