import React, { useEffect, useState } from 'react'

import { getFetchMethods, useLanguageContext } from '@cvg/react-hooks'

import auth from '@services/auth'

import Page from '@components/Page'

import styles from './styles.module.css'

const ApiMetadata = ({ metadata }) => {
  if (metadata.length === 0) return null

  const { languageId } = useLanguageContext()
  const [values, setValues] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const { get } = getFetchMethods({ auth })

  useEffect(() => {
    const getDynamicMetadata = async () => {
      setIsLoading(true)
      const promises = await Promise.allSettled(
        metadata.map(async item => {
          const response = await get(item.value.replace('{locale}', languageId))

          return { label: item.label, text: response }
        })
      )
      const metadataValues = promises.filter(promise => promise.status === 'fulfilled').map(promise => promise.value)
      setValues(metadataValues)
      setIsLoading(false)
    }

    getDynamicMetadata()
  }, [])

  return (
    <Page.AsyncContent isLoading={isLoading} isShowSpinner={false}>
      <div className={styles['api-metadata']}>
        <ul>
          {values.map((item, idx) => (
            <li key={`api-metadata-${idx}`}>
              <h3>{item?.label}:</h3>
              <p>{item?.text}</p>
            </li>
          ))}
        </ul>
      </div>
    </Page.AsyncContent>
  )
}

export default ApiMetadata
