import React from 'react'

import { Input } from '@cvg/react-components'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import { USER_MODES } from '@utils/constants'

const Contexts = () => {
  const { callbacks, currentUser, state } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const { setUser } = callbacks

  return (
    <fieldset>
      <legend>{getTranslation('Contexts')}</legend>
      <Input
        displayOptions={{
          showAllOptions: state.mode === USER_MODES.addUser,
          showUnselectedLabel: getTranslation('Add Context'),
          hideUnselectedLabel: getTranslation('Hide Unselected Contexts')
        }}
        name='contexts'
        options={currentUser.permittedContexts}
        onSelect={selectedContexts => setUser('contexts', selectedContexts)}
        selected={state.user.contexts}
        type='multiselect'
      />
    </fieldset>
  )
}

export default Contexts
