import React from 'react'

const Notification = ({ enabled, title }) => (
  <svg data-enabled={enabled} xmlns='http://www.w3.org/2000/svg' viewBox='0 -2 24 24'>
    <title>{title}</title>
    <path d='M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z' />
  </svg>
)

export default Notification
