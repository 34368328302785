import React from 'react'

import { Input } from '@cvg/react-components'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import { USER_MODES } from '@utils/constants'

const Countries = () => {
  const { callbacks, currentUser, state } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const { setUser } = callbacks

  return (
    <fieldset>
      <legend>{getTranslation('Countries')}</legend>
      <Input
        displayOptions={{
          showAllOptions: state.mode === USER_MODES.addUser,
          showUnselectedLabel: getTranslation('Add Country'),
          hideUnselectedLabel: getTranslation('Hide Unselected Countries')
        }}
        name='countries'
        options={currentUser.permittedCountries}
        onSelect={selectedCountries => setUser('countries', selectedCountries)}
        selected={state.user.countries}
        type='multiselect'
      />
    </fieldset>
  )
}

export default Countries
