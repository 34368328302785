import React from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const DeactivateUser = () => {
  const { callbacks, currentUser, state } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const { deactivateUser, setShowDeactivationWarning } = callbacks

  if (!currentUser.canDeactivateUser(state)) return null

  return (
    <div className={styles.deactivate}>
      {!state.showDeactivationWarning && (
        <button name='deactivate' onClick={() => setShowDeactivationWarning(true)}>
          {getTranslation('Deactivate User')}
        </button>
      )}

      {state.showDeactivationWarning && (
        <>
          <p>
            <strong>{getTranslation('Warning')}:&nbsp;</strong>
            <span>{getTranslation('___ will no longer be able to log in.', state.user.email)}</span>
          </p>
          <div>
            <button name='cancel' onClick={() => setShowDeactivationWarning(false)}>
              {getTranslation('Cancel')}
            </button>
            <button name='confirm' onClick={deactivateUser}>
              {getTranslation('Confirm Deactivation')}
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default DeactivateUser
