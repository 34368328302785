import { getFetchMethods } from '@cvg/react-hooks'

import auth from '@services/auth'
import { getContextHelpTextSource } from '@utils/config'

export const getHelpText = async pathFromCacheKey => {
  const { get } = getFetchMethods({ auth })

  if (!pathFromCacheKey || typeof pathFromCacheKey !== 'string') {
    return {}
  }

  return await get(pathFromCacheKey)
}

export const getHelpTextCacheKey = (context, languageId) => {
  const contextHelpTextSource = getContextHelpTextSource(context)

  if (!contextHelpTextSource) {
    return null
  }

  return contextHelpTextSource.replace('{locale}', languageId)
}
