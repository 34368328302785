import React from 'react'

import { ModalButtons } from '@cvg/react-components'

import styles from './styles.module.css'

const { PhotoModalButton } = ModalButtons

const PhotoMetadata = ({ metadata }) => {
  if (metadata.length === 0) return null

  return (
    <div className={styles['photo-metadata']}>
      <ul>
        {metadata.map((item, idx) => (
          <li key={`photo-metadata-${idx}`}>
            <PhotoModalButton photo={item.value}>
              <img src={item.value} />
            </PhotoModalButton>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default PhotoMetadata
