import { STEP_ACTIONS } from '@utils/constants'

const reviewSteps = [
  {
    action: STEP_ACTIONS.noConfig,
    captionShort: 'Ooops!',
    errors: {},
    contentCardsConfig: [],
    number: 1,
    flags: []
  }
]

export default { reviewSteps, helpTextSource: null }
