import auth from '@cvg/otp-auth'

const authConfig = {
  clientId: import.meta.env.PUBLIC_COGNITO_CLIENT_ID,
  poolId: import.meta.env.PUBLIC_COGNITO_USER_POOL_ID,
  options: {
    normalizeUsername: true
  }
}

export default auth(authConfig)
