import React from 'react'

import { Input } from '@cvg/react-components'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import { TRANSLATION_TYPES } from '@utils/constants'

import FlagContent from '../../FlagContent'

import styles from './styles.module.css'

const Textarea = ({ content }) => {
  const { currentStep, updateData } = useCustomContext()

  const { getTranslation } = useLanguageContext()

  return (
    <div className={styles.textarea}>
      <h3>{getTranslation(content.label)}</h3>
      <ul>
        {content.contentItems.map(item => (
          <li key={item.id}>
            <Input
              aria-label={content.label}
              error={currentStep.errors[item.id]}
              onChange={event => updateData(event, currentStep, item.translation ? TRANSLATION_TYPES.target : undefined)}
              name={item.id}
              readOnly={item.readOnly}
              title={item.readOnly ? getTranslation('This value may not be edited') : ''}
              type='flexTextarea'
              value={item.translation ? item.translation.target.data : item.data}
            />
            <FlagContent content={item} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Textarea
