import React from 'react'

import AsyncContent from './AsyncContent'
import ContextProvider from './ContextProvider'
import Header from './Header'
import Main from './Main'

import styles from './styles.module.css'

const Page = ({ children, className = '' }) => {
  return <div className={`${styles.page} ${className}`.trim()}>{children}</div>
}

Page.AsyncContent = AsyncContent
Page.ContextProvider = ContextProvider
Page.Header = Header
Page.Main = Main

export default Page
