import { useEffect, useRef, useState } from 'react'

export const useVideoTimestamp = video => {
  const [timestamp, setTimestamp] = useState({})
  const [idPlaying, setIdPlaying] = useState(null)
  const [isPaused, setIsPaused] = useState(false)

  const timestampRef = useRef(timestamp)

  const controller = new AbortController()

  // Set ref and state with latest timestamp of user clicking play button
  const playTimestamp = (startAt, endAt, id) => {
    timestampRef.current = { startAt, endAt, id }
    setTimestamp({ startAt, endAt, id })
    setIsPaused(false)
  }

  const pauseTimestamp = () => {
    setIsPaused(true)
    video.pause()
  }

  const resumeTimestamp = () => {
    setIsPaused(false)
    video.play()
  }

  // Pause video at endTime
  const pauseVideoAtEndTime = () => {
    setIsPaused(video.paused)

    if (video.currentTime >= timestampRef.current.endAt / 1000.0 || video.ended) {
      timestampRef.current = {}
      setTimestamp({})
      setIdPlaying(null)
      video.pause()
    }
  }

  // Add event listener
  useEffect(() => {
    video?.addEventListener('timeupdate', pauseVideoAtEndTime, { signal: controller.signal })

    return () => controller.abort()
  }, [video])

  // Start video at startTime
  useEffect(() => {
    if (video && timestamp.startAt !== undefined) {
      if (video.readyState >= 3) {
        setIdPlaying(timestamp.id)
        video.currentTime = timestamp.startAt / 1000.0
        video.play()
      } else {
        setTimestamp({})
        setIdPlaying(null)
      }
    }
  }, [timestamp])

  return { idPlaying, isPaused, playTimestamp, pauseTimestamp, resumeTimestamp }
}
