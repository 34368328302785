import React from 'react'

import Page from '@components/Page'
import Users from '@components/Users'
import UserDialog from '@components/UserDialog'

import useUserAdminData from './useUserAdminData'

const UserAdmin = () => {
  const { isLoading, error, ...rest } = useUserAdminData()

  return (
    <Page>
      <Page.Header />
      <Page.Main>
        <Page.AsyncContent isError={!!error} isLoading={isLoading}>
          <Page.ContextProvider {...rest}>
            <Users />
            <UserDialog />
          </Page.ContextProvider>
        </Page.AsyncContent>
      </Page.Main>
    </Page>
  )
}

export default UserAdmin
