import React from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'
import { Input } from '@cvg/react-components'

import { TRANSLATION_TYPES } from '@utils/constants'

import FlagContent from '../../FlagContent'

import styles from './styles.module.css'

const Select = ({ content }) => {
  const { currentStep, updateData } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  return (
    <div className={styles.select}>
      <h3>{getTranslation(content.label)}</h3>
      <ul>
        {content.contentItems.map(item => (
          <li key={item.id}>
            <Input
              className={styles.select}
              error={currentStep.errors[name]}
              name={item.id}
              onChange={event => updateData(event, currentStep, item.translation ? TRANSLATION_TYPES.target : undefined)}
              readOnly={item.readOnly}
              title={item.readOnly ? getTranslation('This value may not be edited') : ''}
              type='select'
              value={item.translation ? item.translation.target.data : item.data}
            >
              {item.allowedValues.map(item => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                )
              })}
            </Input>

            <FlagContent content={item} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Select
