import React from 'react'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'
import { Dialog, Input } from '@cvg/react-components'

import { localizeArray } from '@utils/localization'

import styles from './styles.module.css'

const FlagContent = ({ content, onFlagContent }) => {
  const { currentStep, flagContent } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const handleFlagContent = flags => {
    flagContent(content.id, flags, currentStep)
    onFlagContent && onFlagContent(flags)
  }

  return (
    <Dialog>
      <Dialog.Open aria-label={getTranslation('Flag Content')} className={styles.flag} data-flagged={!!content.flags.length}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
          <path d='M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z' />
        </svg>
      </Dialog.Open>

      <Dialog.Container className={styles.flags}>
        <Dialog.Content>
          <Input
            name='flags'
            type='multiselect'
            options={localizeArray(content.validFlags, getTranslation)}
            onSelect={handleFlagContent}
            selected={content.flags}
          />

          <Dialog.Close>{getTranslation('Close')}</Dialog.Close>
        </Dialog.Content>
      </Dialog.Container>
    </Dialog>
  )
}

export default FlagContent
