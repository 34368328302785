import React from 'react'

import { Input } from '@cvg/react-components'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import { TRANSLATION_TYPES } from '@utils/constants'

import FlagContent from '../../../FlagContent'

import styles from './styles.module.css'

const TranslationTarget = ({ contentItems }) => {
  const { currentStep, updateData } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  return (
    <ul className={styles.target}>
      {contentItems.map(item => (
        <li key={item.id}>
          <Input
            aria-labelledby={`${TRANSLATION_TYPES.target}_language`}
            key={item.id}
            name={`target_${item.id}`}
            onChange={event => updateData(event, currentStep, TRANSLATION_TYPES.target)}
            readOnly={item.translation.target.readOnly}
            title={item.translation.target.readOnly ? getTranslation('This value may not be edited') : ''}
            type='flexTextarea'
            value={item.translation.target.data}
          />
          <FlagContent content={item} />
        </li>
      ))}
    </ul>
  )
}

export default TranslationTarget
