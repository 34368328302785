import mixpanel from 'mixpanel-browser'

const token = import.meta.env.PUBLIC_MIXPANEL_TOKEN

mixpanel.init(token, { persistence: 'localStorage' })

export const identifyUser = email => {
  mixpanel.identify(email)
  mixpanel.people.set_once('email', email)
}

export const resetUser = () => {
  mixpanel.reset()
}

export const startReviewTimer = () => mixpanel.time_event('reviewed')

export const endReviewTimer = review =>
  mixpanel.track('reviewed', {
    reviewId: review.id,
    context: review.context,
    sourceLanguage: review.sourceLanguage,
    targetLanguage: review.targetLanguage
  })
