import React from 'react'

import { useCustomContext } from '@cvg/react-hooks'

import DeleteIcon from '@components/Icons/Delete'

import styles from './styles.module.css'

const DeleteSubtitle = ({ subtitleId, transcription }) => {
  const { currentStep, updateData } = useCustomContext()

  const removeSubtitle = () => {
    const event = {
      target: {
        name: transcription.id,
        value: transcription.data.filter(data => data.id !== subtitleId)
      }
    }

    updateData(event, currentStep)
  }

  if (transcription.readOnly) return null

  return (
    <button className={styles.delete} onClick={removeSubtitle} type='button'>
      <DeleteIcon />
    </button>
  )
}

export default DeleteSubtitle
