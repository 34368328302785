import React from 'react'

import { useLanguageContext } from '@cvg/react-hooks'
import { useVideoTimestamp } from '@hooks/useVideoTimestamp'

import FlagContent from '@components/ContentCard/FlagContent'
import Subtitles from './Subtitles'

import styles from './styles.module.css'

const VideoTranscription = ({ content }) => {
  const { getTranslation } = useLanguageContext()

  const transcription = content.contentItems[0]
  const video = useVideoTimestamp(document.getElementById('video'))

  return (
    <div className={styles.transcription}>
      <h3>{getTranslation(content.label)}</h3>
      <Subtitles transcription={transcription} video={video} />
      <FlagContent content={transcription} />
    </div>
  )
}

export default VideoTranscription
