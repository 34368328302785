import React, { useState } from 'react'

import { useLanguageContext } from '@cvg/react-hooks'
import { TRANSLATION_TYPES } from '@utils/constants'

import Tabs from './Tabs'
import TranslationSource from './TranslationSource'
import TranslationTarget from './TranslationTarget'

import styles from './styles.module.css'

const Translation = ({ content, languages }) => {
  const [tabView, setTabView] = useState(TRANSLATION_TYPES.source)
  const { getTranslation } = useLanguageContext()

  return (
    <div className={styles.translation} data-view={tabView}>
      <h3>{getTranslation(content.label)}</h3>
      <Tabs view={tabView} languages={languages} setView={setTabView} />
      <div className={styles.translations}>
        <TranslationSource contentItems={content.contentItems} />
        <TranslationTarget contentItems={content.contentItems} />
      </div>
    </div>
  )
}

export default Translation
