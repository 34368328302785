import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { LanguageContextProvider } from '@cvg/react-hooks'

import localize, { localizationOptions } from '@utils/localization'

import { SearchContextProvider } from '@components/AppProviders/Search'
import AppRoutes from '@components/AppRoutes'

const App = () => {
  return (
    <LanguageContextProvider localize={localize} options={localizationOptions} storage={localStorage}>
      <BrowserRouter>
        <SearchContextProvider>
          <AppRoutes />
        </SearchContextProvider>
      </BrowserRouter>
    </LanguageContextProvider>
  )
}

export default App
