import React from 'react'

import { useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const UserContexts = ({ user: { contexts } }) => {
  const { getTranslation } = useLanguageContext()

  return (
    <ul className={styles['user-contexts']}>
      {contexts.toSorted().map(context => (
        <li key={context}>{getTranslation(context)}</li>
      ))}
    </ul>
  )
}

export default UserContexts
