import React from 'react'

import { useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const ContentPreview = ({ record: { description } }) => {
  const { getTranslation } = useLanguageContext()

  return (
    <div className={styles.preview}>
      <p data-empty={getTranslation('No text')}>{description}</p>
    </div>
  )
}

export default ContentPreview
