import React from 'react'

import { AsyncContent as CVGAsyncContent } from '@cvg/react-components'
import { useLanguageContext } from '@cvg/react-hooks'

const AsyncContent = ({
  children,
  errorFallback,
  loadingFallback,
  isError = false,
  isLoading = false,
  isShowSpinner = true
}) => {
  const { getTranslation } = useLanguageContext()

  return (
    <CVGAsyncContent
      errorFallback={errorFallback || getTranslation('ERROR_MESSAGE')}
      loadingFallback={loadingFallback || getTranslation('LOADING_MESSAGE')}
      isError={isError}
      isLoading={isLoading}
      isShowSpinner={isShowSpinner}
    >
      {children}
    </CVGAsyncContent>
  )
}

export default AsyncContent
