import React from 'react'

import { USER_MODES } from '@utils/constants'

import UserInfo from './UserInfo'
import UserLocales from './UserLocales'
import UserContexts from './UserContexts'

import styles from './styles.module.css'

const UserCard = ({ openUser, user }) => {
  return (
    <li className={styles['user-card']}>
      <button onClick={() => openUser(USER_MODES.updateUser, user)}>
        <div className={styles['card-title']}>
          <UserInfo user={user} />
        </div>

        <div className={styles['card-content']}>
          <UserLocales user={user} />
          <UserContexts user={user} />
        </div>
      </button>
    </li>
  )
}

export default UserCard
