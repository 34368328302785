import React from 'react'

import Timestamps from './Timestamps'
import Text from './Text'
import DeleteSubtitle from '../DeleteSubtitle'

import styles from './styles.module.css'

const Subtitle = ({ subtitle, transcription, video }) => {
  const { endTime, id, startTime, text } = subtitle

  return (
    <div className={styles.subtitle} data-readonly={transcription.readonly}>
      <Timestamps
        subtitleId={id}
        endTime={endTime}
        startTime={startTime}
        isPlaying={video.idPlaying === `${transcription.id}-${id}`}
        isPaused={video.idPlaying === `${transcription.id}-${id}` && video.isPaused}
        play={() => video.playTimestamp(startTime, endTime, `${transcription.id}-${id}`)}
        pause={video.pauseTimestamp}
        resume={video.resumeTimestamp}
        transcription={transcription}
      />
      <Text subtitleId={id} text={text} transcription={transcription} />
      <DeleteSubtitle subtitleId={id} transcription={transcription} />
    </div>
  )
}

export default Subtitle
