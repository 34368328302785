import React from 'react'

import { Input } from '@cvg/react-components'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import DeactivateUser from '../DeactivateUser'

const BasicInfo = () => {
  const { callbacks, currentUser, state } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const { setUser } = callbacks

  return (
    <fieldset>
      <legend>{getTranslation('Basic Info')}</legend>
      <div data-flex>
        <Input
          label={getTranslation('Role')}
          name='role'
          onChange={e => setUser(e.target.name, e.target.value)}
          type='select'
          value={state.user.role}
        >
          {currentUser.permittedRoles.map(role => (
            <option key={role} value={role}>
              {getTranslation(role)}
            </option>
          ))}
        </Input>

        <Input
          label={getTranslation('Name')}
          name='name'
          onChange={e => setUser(e.target.name, e.target.value)}
          type='text'
          value={state.user.name}
        />

        <Input
          label={getTranslation('Email')}
          name='email'
          onChange={e => setUser(e.target.name, e.target.value)}
          type='email'
          value={state.user.email}
        />

        <DeactivateUser />
      </div>
    </fieldset>
  )
}

export default BasicInfo
