import React from 'react'
import { useCustomContext, useFetch, useLanguageContext } from '@cvg/react-hooks'

import { getHelpText, getHelpTextCacheKey } from '@services/helpText'
import { IMMUTABLE_OPTIONS } from '@utils/swr'

import Page from '@components/Page'

import styles from './styles.module.css'

const HelpText = () => {
  const { getTranslation, languageId } = useLanguageContext()
  const { context, showHelpText } = useCustomContext()

  const { data, error, isLoading } = useFetch(getHelpText, {
    cacheKey: getHelpTextCacheKey(context, languageId),
    swrOptions: IMMUTABLE_OPTIONS
  })

  const text = data?.body || getTranslation('DEFAULT_HELP')

  return (
    <div className={styles['help-text']} data-visible={showHelpText}>
      <Page.AsyncContent isError={!!error} isLoading={isLoading}>
        <h3>{data?.title || getTranslation('Approval Guidelines')}</h3>
        <div
          className={styles.body}
          dangerouslySetInnerHTML={{ __html: text.replaceAll('<a ', "<a target='_blank' rel='noreferrer'") }}
        />
      </Page.AsyncContent>
    </div>
  )
}

export default HelpText
