import React from 'react'
import { Loader } from '@cvg/react-components'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const DialogActions = () => {
  const { getTranslation } = useLanguageContext()
  const { callbacks, isSubmitting, state } = useCustomContext()

  const { reset, submitUser } = callbacks
  const { email, contexts, countries, languages, name } = state.user

  const isSubmitDisabled = !email || !contexts.length || !countries.length || !languages.length || !name || isSubmitting

  return (
    <div className={styles.buttons}>
      <button name='cancel' onClick={reset}>
        {getTranslation('Cancel')}
      </button>
      <button name='submit' disabled={isSubmitDisabled} onClick={submitUser}>
        {isSubmitting ? <Loader /> : getTranslation('Submit')}
      </button>
    </div>
  )
}

export default DialogActions
