import React from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import Subtitle from '../Subtitle'
import AddSubtitle from '../AddSubtitle'

import styles from './styles.module.css'

const Subtitles = ({ transcription, video }) => {
  const { currentStep } = useCustomContext()
  const { getTranslation } = useLanguageContext()
  const error = currentStep.errors[transcription?.id]

  return (
    <>
      <div className={styles.subtitles} data-error={error}>
        {transcription?.data.length === 0 && (
          <p className={styles['no-subtitles']}>{getTranslation('No speech detected.')}</p>
        )}

        {transcription?.data.map(subtitle => (
          <Subtitle key={subtitle.id} subtitle={subtitle} transcription={transcription} video={video} />
        ))}

        <AddSubtitle transcription={transcription} />
      </div>
      <div className={styles.error}>{getTranslation(error)}</div>
    </>
  )
}

export default Subtitles
