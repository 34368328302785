import { mutate } from 'swr'
import { sortByNameAndEmail } from '../user'

export const CACHE_KEYS = Object.freeze({
  user: `${import.meta.env.PUBLIC_APP_ID}/user`,
  users: `${import.meta.env.PUBLIC_APP_ID}/users`
})

export const IMMUTABLE_OPTIONS = Object.freeze({
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
})

export const getOptimisticUIReviews = (recordId, pages) => {
  return pages?.map(page => {
    const reviews = page.reviews.filter(record => record.id !== recordId)

    return { ...page, reviews }
  })
}

export const getOptimisticUIUser = (previousUser, updates) => {
  return { ...previousUser, ...updates }
}

export const removeUserFromCache = (id, users) => {
  mutate(
    CACHE_KEYS.users,
    users.filter(user => user.userId !== id)
  )
}

export const updateUserCaches = (updatedUser, currentUser, previousUsers) => {
  if (updatedUser.email === currentUser?.email) mutate(CACHE_KEYS.user, updatedUser)

  if (previousUsers) {
    const userExists = previousUsers.map(user => user.email).includes(updatedUser.email)

    if (userExists) {
      const otherUsers = previousUsers.filter(user => user.email !== updatedUser.email)
      const updatedUsers = [...otherUsers, updatedUser].sort(sortByNameAndEmail)

      mutate(CACHE_KEYS.users, updatedUsers)
    } else {
      const updatedUsers = [...previousUsers, updatedUser].sort(sortByNameAndEmail)

      mutate(CACHE_KEYS.users, updatedUsers)
    }
  }
}
