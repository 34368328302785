import { VALIDATIONS } from '@utils/validations'
import { CONTEXT_GROUP_BY, MEDIA_TYPES, STEP_ACTIONS } from '@utils/constants'
import { rejectionReasons } from '@cvg/content-review-localizations'

const { required } = VALIDATIONS

const reviewSteps = [
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Progress Update',
    captionShort: 'Progress',
    contentCardsConfig: [
      [
        {
          label: 'Title',
          contentFilters: { role: 'title' },
          validations: [required],
          validFlags: [
            rejectionReasons.duplicateUpdateRequest,
            rejectionReasons.titleIsUnclear,
            rejectionReasons.inappropriateContent
          ]
        }
      ],
      [
        {
          label: 'Description',
          contentFilters: { role: 'description' },
          validations: [required],
          validFlags: [
            rejectionReasons.poorDescription,
            rejectionReasons.needMoreInformation,
            rejectionReasons.inappropriateContent,
            rejectionReasons.notAlignedWithCauseRequest
          ]
        }
      ]
    ]
  },
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Photos',
    captionShort: 'Photos',
    contentCardsConfig: [
      [
        {
          contentFilters: { mediaType: MEDIA_TYPES.image },
          validFlags: [
            rejectionReasons.photoExcessivelyBlurry,
            rejectionReasons.inappropriateContent,
            rejectionReasons.imageDoesNotShowcaseTheCauseRequest
          ]
        },
        {
          label: 'Caption',
          contentFilters: { role: 'caption' },
          isOptional: true,
          validFlags: [rejectionReasons.inappropriateContent, rejectionReasons.captionDoesNotMatchImage],
          validations: [required]
        }
      ]
    ]
  },
  { action: STEP_ACTIONS.submit, captionLong: 'Submit', captionShort: 'Submit' }
]

export default { reviewSteps, sortBy: { groupBy: CONTEXT_GROUP_BY.causes, sortOrder: 3 } }
