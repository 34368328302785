import React from 'react'

import { useLanguageContext } from '@cvg/react-hooks'

import { MAX_DISPLAYED_COUNTRIES, MAX_DISPLAYED_LANGUAGES } from '@utils/constants'

import styles from './styles.module.css'

const UserLocales = ({ user: { countries, languages } }) => {
  const { getTranslation } = useLanguageContext()

  const renderMoreCount = (count, max) => {
    return count > max ? `, +${count - max}` : ''
  }

  return (
    <div className={styles['user-location']}>
      <span data-warning={!countries.length ? getTranslation('Country Not Selected') : null}>
        {countries
          .map(country => getTranslation(country))
          .toSorted()
          .slice(0, MAX_DISPLAYED_COUNTRIES)
          .join(', ')}
        {renderMoreCount(countries.length, MAX_DISPLAYED_COUNTRIES)}
      </span>
      <i>
        ({languages.slice(0, MAX_DISPLAYED_LANGUAGES).toSorted().join(', ')}
        {renderMoreCount(languages.length, MAX_DISPLAYED_LANGUAGES)})
      </i>
    </div>
  )
}

export default UserLocales
