import React from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import PlayIcon from '@components/Icons/Play'
import PauseIcon from '@components/Icons/Pause'
import TimeInput from './TimeInput'

import styles from './styles.module.css'

const Timestamps = ({ subtitleId, startTime, endTime, isPlaying, isPaused, play, pause, resume, transcription }) => {
  const { currentStep, updateData } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const handleOnChange = ({ target }) => {
    const { name, value } = target
    const [id, type] = name.split('-')

    const event = {
      target: {
        name: transcription.id,
        value: transcription.data.map(data => (data.id === id ? { ...data, [type]: value } : data))
      }
    }

    updateData(event, currentStep)
  }

  return (
    <div className={styles.timestamps} data-playing={isPlaying}>
      <TimeInput
        name={`${subtitleId}-startTime`}
        label={getTranslation('Starts')}
        onChange={handleOnChange}
        readOnly={transcription.readOnly}
        value={startTime}
      />
      <TimeInput
        label={getTranslation('Ends')}
        name={`${subtitleId}-endTime`}
        onChange={handleOnChange}
        readOnly={transcription.readOnly}
        value={endTime}
      />

      <button onClick={isPaused ? resume : isPlaying ? pause : play}>
        {isPlaying && !isPaused ? <PauseIcon /> : <PlayIcon />}
      </button>
    </div>
  )
}

export default Timestamps
