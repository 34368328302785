import { jwtDecode } from 'jwt-decode'

import { useLanguageContext } from '@cvg/react-hooks'

import {
  CONTEXT_TYPES,
  DEFAULT_UPSERT_USER_ERROR_MESSAGE,
  SUPPORTED_REVIEW_COUNTRIES,
  SUPPORTED_REVIEW_LANGUAGES,
  ERROR_STATUS_UPSERT_USER_FORBIDDEN,
  USER_MODES,
  USER_ROLES
} from '@utils/constants'

import auth from '@services/auth'
import logger from '@utils/logger'

export const getUpsertUserErrorMessage = (error, getTranslation) => {
  switch (error.status) {
    case ERROR_STATUS_UPSERT_USER_FORBIDDEN:
      return _getUpsertUserForbiddenErrorMessage(error.message, getTranslation)
    default:
      return getTranslation(DEFAULT_UPSERT_USER_ERROR_MESSAGE)
  }
}

export const getUserData = async () => {
  let token

  try {
    token = await _getToken()

    if (token) {
      return {
        emailAddress: _getEmailAddress(token)
      }
    } else return {}
  } catch (error) {
    logger.error('getUserData()', { error, token })
    return {}
  }
}

export const filterUsers = (users, filters) => {
  return users
    .filter(user =>
      [user.name?.toLowerCase(), user.email.toLowerCase()].some(item => item?.includes(filters.nameOrEmail.toLowerCase()))
    )
    .filter(user => user.role.includes(filters.role) || !filters.role)
}

export const sortByNameAndEmail = (a, b) => {
  return a.name?.localeCompare(b.name) || a.email.localeCompare(b.email)
}

/********* User Admin Permissions *********/
export const getUserPermissions = user => {
  const { getTranslation } = useLanguageContext()

  user.canManageUsers = _canManageUsers(user)
  user.permittedContexts = _getPermittedContexts(user, getTranslation)
  user.permittedCountries = _getPermittedCountries(user, getTranslation)
  user.permittedLanguages = _getPermittedLanguages(user, getTranslation)
  user.permittedRoles = _getPermittedRoles(user, getTranslation)

  user.canDeactivateUser = state => _canDeactivateUser(state, user)
}

export const _canDeactivateUser = (state, currentUser) => {
  const { mode, user } = state

  const isUpdatingAUser = mode === USER_MODES.updateUser
  const isDeactivatingSelf = user.email === currentUser.email

  if (isUpdatingAUser && !isDeactivatingSelf && currentUser.canManageUsers) {
    return true
  } else {
    return false
  }
}

const _canManageUsers = user => {
  return [USER_ROLES.owner, USER_ROLES.admin].includes(user.role)
}

const _getPermittedContexts = (user, getTranslation) => {
  switch (user.role) {
    case USER_ROLES.owner:
    case USER_ROLES.admin:
      return _getOptionsMap(Object.values(CONTEXT_TYPES), getTranslation)
  }
}

const _getPermittedCountries = (user, getTranslation) => {
  switch (user.role) {
    case USER_ROLES.owner:
      return _getOptionsMap(SUPPORTED_REVIEW_COUNTRIES, getTranslation)
    case USER_ROLES.admin:
      return _getOptionsMap(user.countries, getTranslation)
  }
}

const _getPermittedLanguages = (user, getTranslation) => {
  switch (user.role) {
    case USER_ROLES.owner:
    case USER_ROLES.admin:
      return _getOptionsMap(SUPPORTED_REVIEW_LANGUAGES, getTranslation)
  }
}

const _getPermittedRoles = user => {
  switch (user.role) {
    case USER_ROLES.owner:
      return Object.values(USER_ROLES)
    case USER_ROLES.admin:
      return [USER_ROLES.reviewer, USER_ROLES.admin]
  }
}

/********* Helper Functions *********/
const _getEmailAddress = ({ email }) => {
  if (email) return email

  throw new Error('No Valid Email Address Found')
}

const _getOptionsMap = (items, getTranslation) => {
  const alphabetizedItemEntries = items.map(item => [item, getTranslation(item)]).sort((a, b) => (a[1] > b[1] ? 1 : -1))

  return new Map(alphabetizedItemEntries)
}

const _getToken = async () => {
  try {
    const token = await auth.getToken()

    return token ? jwtDecode(token) : null
  } catch {
    return null
  }
}

const _getUpsertUserForbiddenErrorMessage = (message, getTranslation) => {
  if (message.includes(':')) {
    const messageParts = message.split(':')

    const messageText = getTranslation(`${messageParts[0]}:`)
    const messageData = messageParts[1]
      .split(',')
      .map(item => getTranslation(item.trim()))
      .join(', ')

    return `${messageText} ${messageData}`
  } else {
    return getTranslation(message)
  }
}
