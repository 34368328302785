import { getFetchMethods } from '@cvg/react-hooks'

import auth from '@services/auth'
import logger from '@utils/logger'

const apiKey = import.meta.env.PUBLIC_CONTENT_REVIEW_API_KEY
const baseUri = import.meta.env.PUBLIC_CONTENT_REVIEW_BASE_URI

export const { del, get, patch, post } = getFetchMethods({ apiKey, auth, baseUri, logger })
export const { put } = getFetchMethods({ apiKey, baseUri, logger })
