import React from 'react'

import { useLanguageContext } from '@cvg/react-hooks'

import ArrowIcon from '@components/Icons/Arrow'
import NotificationIcon from '@components/Icons/Notification'

import styles from './styles.module.css'

const UserInfo = ({ user: { name, email, notificationsEnabled, role } }) => {
  const { getTranslation } = useLanguageContext()

  return (
    <div className={styles['user-info']}>
      <div className={styles['name-email']}>
        <em>{name}</em>
        <div>
          <small>{email}</small>
          <NotificationIcon enabled={notificationsEnabled} title={getTranslation(`NOTIFICATIONS_${notificationsEnabled}`)} />
        </div>
      </div>

      <div className={styles.role}>
        {getTranslation(role)}
        <ArrowIcon />
      </div>
    </div>
  )
}

export default UserInfo
