import React from 'react'

import { Dialog } from '@cvg/react-components'
import { useCustomContext } from '@cvg/react-hooks'

import BasicInfo from './BasicInfo'
import Notifications from './Notifications'
import Contexts from './Contexts'
import Countries from './Countries'
import Languages from './Languages'
import DialogActions from './DialogActions'

import styles from './styles.module.css'

const UserDialog = () => {
  const { callbacks, state } = useCustomContext()

  return (
    <Dialog isOpen={!!state.mode} onClose={callbacks.reset}>
      <Dialog.Container className={styles.dialog}>
        <Dialog.Content>
          <BasicInfo />
          <Notifications />
          <Contexts />
          <Countries />
          <Languages />
          <DialogActions />
        </Dialog.Content>
      </Dialog.Container>
    </Dialog>
  )
}

export default UserDialog
