import React from 'react'

import { MEDIA_TYPES } from '@utils/constants'

import Photo from './Photo'
import Text from './Text'
import Video from './Video'
import VideoTranscription from './VideoTranscription'

const Media = ({ data, mediaType }) => {
  switch (mediaType) {
    case MEDIA_TYPES.image:
      return <Photo data={data} />

    case MEDIA_TYPES.text:
      return <Text data={data} />

    case MEDIA_TYPES.video:
      return <Video data={data} />

    case MEDIA_TYPES.transcription:
      return <VideoTranscription data={data} />

    default:
      return null
  }
}

export default Media
