import { MEDIA_TYPES, STEP_ACTIONS } from '@utils/constants'
import { E2E_VALUES } from '@e2e/constants'

const reviewSteps = [
  {
    action: STEP_ACTIONS.review,
    captionShort: 'Review',
    captionLong: 'Review',
    contentCardsConfig: [
      [
        {
          contentFilters: { mediaType: MEDIA_TYPES.text },
          validFlags: [E2E_VALUES.flags[0]]
        }
      ],
      [
        {
          contentFilters: { mediaType: MEDIA_TYPES.image },
          validFlags: [E2E_VALUES.flags[1]]
        }
      ],
      [
        {
          contentFilters: { mediaType: MEDIA_TYPES.video },
          validFlags: [E2E_VALUES.flags[2]]
        }
      ]
    ]
  },
  {
    action: STEP_ACTIONS.submit,
    captionShort: 'Submit',
    captionLong: 'Submit'
  }
]

export default { reviewSteps }
