import React from 'react'

import styles from './styles.module.css'

const VideoTranscription = ({ data }) => {
  return (
    <ul className={styles.transcription}>
      {data.map(item => (
        <li key={item.id}>{item.text}</li>
      ))}
    </ul>
  )
}

export default VideoTranscription
