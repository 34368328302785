import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { OtpLogin } from '@cvg/react-otp-auth'
import { useLanguageContext } from '@cvg/react-hooks'

import auth from '@services/auth'
import { identifyUser } from '@utils/analytics'
import { getUserData } from '@utils/user'

import Page from '@components/Page'
import SelectLanguage from '@components/SelectLanguage'

import styles from './styles.module.css'

const Login = () => {
  const navigate = useNavigate()
  const { getTranslation, languageId } = useLanguageContext()

  useEffect(() => {
    // When we unmount Login, track the user for analytics.
    return async () => {
      const { emailAddress } = await getUserData()
      identifyUser(emailAddress)
    }
  })

  return (
    <Page className={styles.login}>
      <div className={styles.wrapper}>
        <header>
          <h1>{getTranslation('Content Review')}</h1>
        </header>
        <div>
          <SelectLanguage className={styles.language} />
          <OtpLogin auth={auth} languageId={languageId} redirectCallback={() => navigate('/')} />
        </div>
      </div>
    </Page>
  )
}

export default Login
