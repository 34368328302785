import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router'
import { useNavigate } from 'react-router-dom'

import auth from '@services/auth'

import { useLanguageContext } from '@cvg/react-hooks'

import AuthenticatedRoutes from './AuthenticatedRoutes'

import Login from '@pages/Login'
import Logout from '@pages/Logout'
import Home from '@pages/Home'
import Review from '@pages/Review'
import UserAdmin from '@pages/UserAdmin'
import Unsubscribe from '@pages/Unsubscribe'

const AppRoutes = () => {
  useLanguageContext()
  const navigate = useNavigate()

  useEffect(() => {
    auth.setSessionExpirationCallback(() => {
      navigate('/logout')
    })
  }, [])

  return (
    <Routes>
      <Route path='/' element={<AuthenticatedRoutes />}>
        <Route index element={<Home />} />
        <Route path='/review/:id' element={<Review />} />
        <Route path='/users' element={<UserAdmin />} />
      </Route>
      <Route path='/login' element={<Login />} />
      <Route path='/logout' element={<Logout />} />
      <Route path='/unsubscribe/:userId' element={<Unsubscribe />} />
    </Routes>
  )
}

export default AppRoutes
