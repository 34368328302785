import React from 'react'
import { Link } from 'react-router-dom'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import ArrowIcon from '@components/Icons/Arrow'

import styles from '../styles.module.css'

const UserAdmin = () => {
  const { currentUser } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  if (!currentUser.canManageUsers) return null

  return (
    <div className={styles.setting}>
      <div>
        <strong>{getTranslation('User Admin')}</strong>
        <p>{getTranslation('Manage permissions for moderators within your country.')}</p>
      </div>
      <Link to='/users'>
        {getTranslation('Go')}
        <ArrowIcon />
      </Link>
    </div>
  )
}

export default UserAdmin
