import React from 'react'

import { useLanguageContext } from '@cvg/react-hooks'

import LinkIcon from '@components/Icons/Link'
import styles from './styles.module.css'

const LinkMetadata = ({ metadata }) => {
  const { getTranslation, languageId } = useLanguageContext()

  if (metadata.length === 0) return null

  return (
    <div className={styles['link-metadata']}>
      <h3>{getTranslation('Helpful Links:')}</h3>
      <ul>
        {metadata.map((item, idx) => (
          <li key={`link-metadata-${idx}`}>
            <a href={item.value.replace('{locale}', languageId)} target='_blank' rel='noopener noreferrer'>
              <LinkIcon />
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default LinkMetadata
