import React from 'react'
import { Link } from 'react-router-dom'

import { useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const Error = () => {
  const { getTranslation } = useLanguageContext()

  return (
    <div className={styles.error}>
      <h1>{getTranslation('Something is not quite right!')}</h1>
      <p>{getTranslation('ERROR_CONFIG')}</p>
      <Link to='/'>{getTranslation('Back to Queue')}</Link>
    </div>
  )
}

export default Error
